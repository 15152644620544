"use strict";

(function () {
  function documentReady() {
    // ====================
    // Project: object-fit
    // 01-02-2017: drtvader
    // ---------------------
    // Запуск object-fit
    // ====================
    if ($('.js-image-wrapper').length > 0) {
      objectFitImages('.js-image-wrapper');
    }

    ; // ====================
    // Project: object-fit
    // 01-02-2017: dimastad
    // ---------------------
    // Запуск object-fit
    // ====================

    if ($('.js-video-wrapper').length > 0) {
      objectFitVideos(document.querySelectorAll('.js-video-wrapper'));
    }

    ; // ====================
    // robinzon: popup.js
    // 15.01.2018: Amedomary
    // ---------------------
    // Открытие попАпа и запрет скрола на body
    // ====================

    var $page = $('.b-page-wrapper');
    var $popUp = $('.b-popup');
    var $popUpContent = $('.b-popup__content');
    var windowsOS = navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
    $('.js-open-popup').on('popup.onClose', function (event) {
      closePopUp();
    }); // Закрытие попАпов

    function closePopUp() {
      // Проверяем открыт ли попАп
      if ($popUp.hasClass('open')) {
        $page.removeClass('no-scroll no-touch windows');
        $popUp.removeClass('open');
        $popUp.stop().fadeOut(300);
      }
    } // Проверка на наличие скролаа


    function getScroll(scroll, selector) {
      var doc = document;
      var body = doc.body;
      var element = doc.querySelector(selector);
      var client = 'client' + scroll;
      scroll = 'scroll' + scroll;
      return /CSS/.test(doc.compatMode) ? element[client] < element[scroll] : body[client] < body[scroll];
    } // Клик по ссылке открывающей попАп


    $(document).on('click', '.js-open-popup', function () {
      $(this).trigger('popup.onOpen');
    }); // Кастомное событие открытие попАп

    $('.js-open-popup').on('popup.onOpen', function () {
      openPopUp.call(this);
    });

    function openPopUp() {
      var $popUpDate = $('.b-popup[data-popup=\'' + $(this).attr('data-popup') + '\']'); // Создаем типа callback

      $(this).trigger('popup.open', [$popUpDate]); // Проверяем есть ли нам что открыть

      if ($popUpDate.length > 0) {
        // Проверяем операционную систему на Win и Скролл
        if (windowsOS && getScroll('Height', '.b-page-wrapper')) {
          $page.addClass('windows');
        } // Закрываем перед открытиме другие


        $popUp.removeClass('open');
        $popUp.fadeOut(300);
        $page.addClass('no-scroll no-touch');
        $popUpDate.addClass('open');
        $popUpDate.css('display', 'flex').hide().fadeIn(300);
      }
    } // Клик по Закрытию попАпов


    $(document).on('click', '.js-close-popup', function () {
      closePopUp();

      if ($('.js-menu-in-popup-back').length) {
        $('.js-menu-in-popup-back').trigger('click');
      }
    }); // Жмяк по Esc

    $(document).on('keydown', function (event) {
      if (event.keyCode === 27) {
        closePopUp();
      }
    });
    $(document).mouseup(function (e) {
      if ($popUp.hasClass('open')) {
        // Клик не по Контенту и не его дочкам
        if (!$popUpContent.is(e.target) && $popUpContent.has(e.target).length === 0) {
          closePopUp();
        }
      }
    });
    ; // ====================
    // Project: menu
    // 16-01-2019: drtvader
    // ---------------------
    // Открытие закрытие меню
    // ====================

    $(document).ready(function () {
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        $('.b-menu').css({
          opasity: 0
        }).slideDown('1').slideUp('1').css({
          opasity: ''
        });
      } else {
        $('.b-menu').hide();
      }
    });

    var toggleMenu = function toggleMenu() {
      $('.js-page-wrapper').toggleClass('no-touch');
      $('.b-page-wrapper').toggleClass('no-touch');
      $('.b-header').toggleClass('open');
      $('.b-hamburger').toggleClass('open');
      $('.b-menu').toggleClass('open');
      $('.b-menu').slideToggle({
        duration: '4500'
      });
    };

    $('.b-hamburger').on('click', function () {
      toggleMenu();
    });
    ;
    $(document).ready(function () {
      var initCopyright = function initCopyright() {
        var scrollTop = $(window).scrollTop();
        var $copyright = $('.js-copyright');
        var $facebook = $('.js-facebook');

        if ($('.js-main').length === 0) {
          return;
        }

        {
          // копирайт
          if (scrollTop > 30) {
            $copyright.removeClass('black-bg');
          } else {
            $copyright.addClass('black-bg');
          }
        }
        {
          // facebook
          if (scrollTop > 405) {
            $facebook.removeClass('black-bg');
          } else {
            $facebook.addClass('black-bg');
          }
        }
      };

      $(window).on('scroll', function () {
        initCopyright();
      });
      initCopyright(); // services.html

      $(document).on('mouseenter', '.b-square--services:first-child', function () {
        $('.b-header__logo-image').addClass('hover');
      });
      $(document).on('mouseleave', '.b-square--services:first-child', function () {
        $('.b-header__logo-image').removeClass('hover');
      });
      $(document).on('mouseenter', '.b-square--services:last-child', function () {
        $('.b-hamburger').addClass('hover');
      });
      $(document).on('mouseleave', '.b-square--services:last-child', function () {
        $('.b-hamburger').removeClass('hover');
      });
    });
    ; // ====================
    // Project: script
    // 00-00-2017: Author
    // ---------------------
    // Описание
    // ====================

    $(document).ready(function () {
      var $form = $('.js-feedback');
      var $inputs = $form.find('input, textarea');
      $inputs.on('keyup', function () {
        var $this = $(this);

        if ($this.val().length) {
          $this.siblings('.js-label').addClass('open');
        } else {
          $this.siblings('.js-label').removeClass('open');
        }
      });
      $(document).on('click', '.js-close-popup', function () {
        var type = $(this).parents('[data-popup]').data('popup');

        if (type === 'form-send') {
          $('.js-feedback')[0].reset();
          $('.js-label').removeClass('open');
          $(window).scrollTop(0);
        }
      });
      $(document).on('click', '.js-goto-feed', function () {
        var top;

        if ($('.b-header').hasClass('open')) {
          toggleMenu();
        }

        setTimeout(function () {
          if ($form.length) {
            top = $form.offset().top;
            $('html,body').stop().animate({
              scrollTop: top
            }, 900);
          } else {
            location.href = '/contact.html#feedback';
          }
        });
      });
      $('[name="user-msg"]').autoheight();
    });
    ; // ====================
    // robinzon: validation
    // 05.04.2018: Malich
    // ---------------------
    // Валидация
    // ====================

    $(document).ready(function () {
      // Сообшения
      $.extend($.validator.messages, {
        required: 'Поле обязательно к заполнению',
        email: 'E-mail некорректен',
        tel: 'Телефон некорректен',
        'geo-city': 'Местоположение не верно',
        'myself-selected': 'Необходимо выбрать',
        minlength: $.validator.format('Введите не менее {0} символов')
      }); // Text

      $.validator.addMethod('text', function (value, element) {
        return this.optional(element) || value.length > 0;
      }, $.validator.messages.required); // E-mail

      $.validator.addMethod('email', function (value, element) {
        /* eslint-disable max-len */
        var rexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return this.optional(element) || rexp.test(value);
      }, $.validator.messages.email); // Tel

      $.validator.addMethod('tel', function (value, element) {
        var rexp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{14,17}$/;
        return this.optional(element) || rexp.test(value);
      }, $.validator.messages.tel);
      $.validator.addMethod('geo-city', function (value, element) {
        var bool = value.toLocaleLowerCase().indexOf(geoLocationName.toLocaleLowerCase()) >= 0;
        return this.optional(element) || bool;
      }, $.validator.messages['geo-city']);
      $.validator.addMethod('myself-selected', function (value, element) {
        var bool = value.length;

        if ($('.js-myself-selected:checked').length === 0) {
          return true;
        }

        return bool;
      }, $.validator.messages['gmyself-selected']);
      $.validator.addMethod('requiredphone', function (value, element) {
        return value.length || $.trim($('[name="ONECLICK[EMAIL]"]').val());
      }, $.validator.messages.required);
      $.validator.addMethod('requiredemail', function (value, element) {
        return value.length || $.trim($('[name="ONECLICK[PERSONAL_PHONE]"]').val());
      }, $.validator.messages.required);
      $.validator.addMethod('onlyRU', function (value, element) {
        return /^[А-ЯЁ][а-яё]*$/ig.test(value);
      }, 'Только русские буквы');
      $('.js-feedback').validate({
        errorElement: 'span',
        submitHandler: function submitHandler(form) {
          $(form).trigger('ajax_submit');
        },
        showErrors: function showErrors(errorMap, errorList) {
          this.defaultShowErrors();
          setTimeout(function () {
            $(errorList).each(function () {
              var $e = $(this.element);
              var id = $e.attr('id');
              $('#' + id + '-error').addClass('b-input__error');

              if (id === 'user-email' && $('#' + id).val().length === 0) {
                $('#' + id + '-error').addClass('b-input__error-black');
                $('#' + id).addClass('error-black');
              } else {
                $('#' + id + '-error').removeClass('b-input__error-black');
                $('#' + id).removeClass('error-black');
              }

              $('.js-feedback').find('[type=\'submit\']').prop('disabled', 'disabled');
            });
          });
        },
        unhighlight: function unhighlight() {
          if (this.numberOfInvalids() === 0 && $('#user-name').val().length && $('#user-email').val().length && $('[name="user-msg"]').val().length && $('#check-policy').prop('checked') === true) {
            $('.js-feedback').find('[type=\'submit\']').prop('disabled', false);
          }
        }
      });
      $('.js-feedback input').on('keyup click', function () {
        $(this).removeClass('error');
        $(this).valid();
      });
    });
    ; // ====================
    // Project: script
    // 00-00-2017: Author
    // ---------------------
    // Аяксы бывают - формы и ссылки
    // ====================

    $(document).ready(function () {
      var initEvents = function initEvents(events, json) {
        var ee = events.split(' ');
        $(ee).each(function () {
          var event = this;
          $('body').trigger({
            type: event,
            json: json
          });
        });
      };

      $(document).on('submit ajax_submit', '.js-ajax-form', function (e) {
        var url = $(this).data('url');
        var dataSend = $(this).serialize();
        var method = $(this).data('method');
        var target = $(this).data('target');
        var events = $(this).data('events');
        e.preventDefault();
        $.ajax({
          url: url,
          method: method,
          data: dataSend,
          success: function success(json) {
            var data = json.data;

            if ($(target).length && Boolean(data.html)) {
              $(target).html(data.html);
            }

            if (Boolean(events)) {
              initEvents(events, json);
            }
          }
        });
        return false;
      });
    });
    ;
    $(document).ready(function () {
      $('body').on('ok_popup', function () {
        $('.js-feedback .js-open-popup').click();
      });
    });
    ; // ====================
    // Project: youtube-video
    // 01-02-2017: dimastad
    // ---------------------
    // youtube-video
    // ====================
    // var player;
    // var tag = document.createElement('script');
    // var firstScriptTag = document.getElementsByTagName('script')[0];
    // tag.src = 'https://www.youtube.com/iframe_api';
    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // function onYouTubePlayerAPIReady() {
    //     alert(5);
    //     player = new YT.Player('Youtube', {
    //         events: {
    //             'onReady': onPlayerReady
    //         }
    //     });
    // }
    // setInterval(function () {
    //     player.pauseVideo();
    // },6000);
    // document.getElementById('pauseYoutube').addEventListener('click', function () {
    //     player.pauseVideo();
    // });

    $(document).ready(function () {
      $('.js-videoframe').on('click', function () {
        $('#Youtube').attr('src', 'https://www.youtube.com/embed/HdPts6ShvVo');
      });
      $('[data-popup="videoframe"] .js-close-popup').on('click', function () {
        $('#Youtube').attr('src', '');
      });
    });
    ; // ====================
    // Project: scrolldown
    // 00-00-2017: dimastad
    // ---------------------
    // scrolldown
    // ====================

    $(document).ready(function () {
      var id;
      var top;
      $('#js-scroll-down').on('click', 'a', function (event) {
        event.preventDefault();
        id = $(this).attr('href'), top = $(id).offset().top;
        $('body,html').animate({
          scrollTop: top
        }, 1000);
      });
    });
    ;
  }

  ;
  document.addEventListener("DOMContentLoaded", documentReady);
})();